import { graphql, PageProps } from 'gatsby';
import React from 'react';
import Posts from '../../components/blog/Posts';
import BlogHeroSection from '../../components/blog/BlogHeroSection';
import TabsSection from '../../components/blog/TabsSection';
import Layout from '../../components/Layout';
import Seo from '../../components/SEO/seo';

const Stories = (page: PageProps) => {
  return (
    <Layout>
      <Seo page={page} title="Flow blog" />
      <main className="blog-page">
        <BlogHeroSection />
        <TabsSection activeTabIndex={5} />
        <Posts categoryFilter="Stories" />
      </main>
    </Layout>
  );
};

export default Stories;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
